import ApiService from "@/core/services/ApiService"
import { AxiosResponse } from "axios"
import paginator from "@/core/helpers/pagination"

export default class AccountService {
  async getAccountInfo(accountNum) {
    ApiService.setHeader()

    let info
    try {
      info = await ApiService.get(`/accounts/${accountNum}`)
    } catch (e) {
      console.error("Problem fetching account info", e)
      return []
    }

    return info.data ? info.data : []
  }

  async getAccountLogo(accountNum: number) {
    let info
    try {
      info = await ApiService.get(`/accounts/${accountNum}/data/logo`)
    } catch (e) {
      console.error("Problem fetching account logo", e)
      return []
    }

    return info.data ? info.data : []
  }

  async getAccountLocationInfo(accountNum: number) {
    let info
    try {
      info = await ApiService.get(`/accounts/${accountNum}/data/location-info`)
    } catch (e) {
      console.error("Problem fetching account logo", e)
      return []
    }

    return info.data ? info.data : []
  }

  async getAccountSurveyInfo(accountNum: number, messageId: string) {
    let info
    try {
      info = await ApiService.get(
        `/accounts/${accountNum}/${messageId}/data/nps_survey`
      )
    } catch (e) {
      console.error("Problem fetching account name and nps_question", e)
      return []
    }

    return info.data ? info.data : []
  }

  async postAccountMessage(
    accountNum,
    body: {
      message_type: string
      first_name?: string
      last_name?: string
      email?: string
      location_id?: string
      send_time?: number
      bulk?: boolean
    }
  ) {
    return AccountService.post(
      "/accounts/" + accountNum + "/messages",
      JSON.stringify(body)
    )
  }

  public static post(resource: string, params: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params)
  }

  async getAccounts() {
    const accounts = await paginator.paginateType("accounts", 800)

    return accounts
  }
}
