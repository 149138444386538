import ApiService from "@/core/services/ApiService"
import PaginateOptions from "@/core/interfaces/PaginationOptions"

const paginator = {
  async paginate(dataType: string, accountNum: number, perPage: number) {
    const resourcesNext = {
      data: {
        data: [],
        links: {
          next:
            "/accounts/" + accountNum + "/" + dataType + "?page_size=" + perPage
        }
      }
    }

    return this.resources(resourcesNext, dataType)
  },

  async paginateLeads(
    dataType: string,
    accountNum: number,
    perPage: number,
    start_date: Date | null = null,
    end_date: Date | null = null
  ) {
    const startString = start_date ? `&start_date=${start_date}` : ""
    const endString = end_date ? `&end_date=${end_date}` : ""
    const pageString = `?page_size=${perPage}`
    const resourcesNext = {
      data: {
        data: [],
        links: {
          next: `/accounts/${accountNum}/${dataType}${pageString}${startString}${endString}`
        }
      }
    }

    return this.resources(resourcesNext, dataType)
  },

  async paginateType(dataType: string, perPage: number) {
    const resourcesNext = {
      data: {
        data: [],
        links: {
          next: `/${dataType}/?page_size=${perPage}`
        }
      }
    }

    return this.resources(resourcesNext, dataType)
  },

  async resources(resourcesNext: PaginateOptions, dataType: string) {
    const resources = {
      data: []
    }

    for (;;) {
      try {
        if (!resourcesNext.data.links || !resourcesNext.data.links.next) break
        resourcesNext = await ApiService.get(resourcesNext.data.links.next)
        resources.data = resources.data.concat(resourcesNext.data.data)
      } catch (e) {
        console.error("Problem fetching " + dataType, e)
        break
      }
    }

    return resources.data ? resources.data : []
  }
}

export default paginator
