import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import store from "@/store"
import { Mutations, Actions } from "@/store/enums/StoreEnums"
import MaintenanceService from "@/core/services/MaintenanceService"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/account/:id",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/account/:id",
        name: "home",
        component: () => import("@/views/pages/Home.vue")
      },
      {
        path: "/account/:id/leads",
        name: "leads",
        component: () => import("@/views/Leads.vue")
      },
      {
        path: "/account/:id/account",
        name: "account",
        component: () => import("@/views/pages/Account.vue")
      },
      {
        path: "/account/:id/support",
        name: "support",
        component: () => import("@/views/pages/Support.vue")
      },
      {
        path: "/account/:id/performance/stats",
        name: "stats",
        component: () => import("@/views/pages/performance/Stats.vue")
      },
      {
        path: "/account/:id/performance/local",
        name: "local",
        component: () => import("@/views/pages/performance/Local.vue")
      },
      {
        path: "/account/:id/tools/manage-contacts",
        name: "manageContacts",
        component: () => import("@/views/pages/tools/ManageContacts.vue")
      },
      {
        path: "/account/:id/tools/get-reviews",
        name: "reviews",
        component: () => import("@/views/pages/tools/GetReviews.vue")
      },
      {
        path: "/account/:id/tools/get-feedback",
        name: "getFeedback",
        component: () => import("@/views/pages/tools/GetFeedback.vue")
      },
      {
        path: "/account/:id/settings/users",
        name: "users",
        component: () => import("@/views/pages/settings/Users.vue")
      },
      {
        path: "/account/:id/settings/service-configurations",
        name: "serviceConfigurations",
        component: () =>
          import("@/views/pages/settings/ServiceConfigurations.vue")
      },
      {
        path: "/account/:id/settings/connections",
        name: "connections",
        component: () => import("@/views/pages/settings/Connections.vue")
      },
      {
        path: "/account/:id/settings/features-display",
        name: "featuresDisplay",
        component: () => import("@/views/pages/settings/FeaturesDisplay.vue")
      },
      {
        path: "/account/:id/maintenance",
        name: "maintenance",
        component: () => import("@/views/pages/Maintenance.vue")
      }
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
    beforeEnter() {
      store.dispatch(Actions.LOGIN)
    }
  },
  {
    path: "/callback",
    name: "callback",
    component: () => import("@/views/auth/Callback.vue")
  },
  {
    path: "/",
    name: "partner",
    component: () => false,
    beforeEnter() {
      window.open("https://partners.highlevelmarketing.com", "_blank")
    }
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue")
  },
  {
    path: "/unsubscribe",
    component: () => import("@/layout/LandingPage.vue"),
    meta: {
      noAuth: true
    },
    children: [
      {
        path: "/unsubscribe/:account_num/:message_id",
        name: "unsubscribe",
        component: () => import("@/views/pages/unsubscribe/Unsubscribe.vue")
      }
    ]
  },
  {
    path: "/feedback-survey",
    component: () => import("@/layout/LandingPage.vue"),
    meta: {
      noAuth: true
    },
    children: [
      {
        path: "/feedback-survey/:account_num/:message_id",
        name: "feedback-survey-no-rating",
        component: () => import("@/views/pages/feedback/FeedbackSurvey.vue")
      },
      {
        path: "/feedback-survey/:account_num/:message_id/:rating",
        name: "feedback-survey",
        component: () => import("@/views/pages/feedback/FeedbackSurvey.vue"),
        beforeEnter(to, from, next) {
          const allowed = new RegExp("^(?:[0-9]|10)$")
          !allowed.test(to.params.rating as string)
            ? next({ name: "404" })
            : next()
        }
      }
    ]
  },
  {
    path: "/account-select",
    name: "account-select",
    component: () => import("@/views/pages/AccountSelect.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  // if someone enters straight to an account page,
  // store the ID to default to that acount if they are a team member
  if (to.matched[1] && to.matched[1].path == "/account/:id" && to.params.id) {
    store.commit(Mutations.SET_ENTRY_INFO_ACCOUNT_ID, String(to.params.id))
  }

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG)

  // wait for store to be recreated from localStorage
  await (store as any).restored

  // @TODO VERIFY_AUTH Action
  // store.dispatch(Actions.VERIFY_AUTH)

  if (
    localStorage.getItem("loggedIn") != "true" &&
    to.path != "/sign-in" &&
    to.path != "/callback" &&
    !to.matched[0].meta.noAuth
  ) {
    next({ name: "sign-in" })
  } else if (
    to.params.id == ":id" &&
    store.getters.activeAccountInfo.id.toString()
  ) {
    await router.push({
      name: "home",
      params: { id: store.getters.activeAccountInfo.id.toString() }
    })
    next()
  }

  // check if page is in maintenance mode
  const maintenance = new MaintenanceService().pageMaintenanceMode(to.name)
  if (maintenance) {
    next({ name: "maintenance", query: { heading: to.name as string } })
  } else {
    // Else required so that next isn't called twice.
    next()
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
