import { Mutations } from "@/store/enums/StoreEnums"

export type State = { classes: object }
import { Module, Mutation, VuexModule } from "vuex-module-decorators"
import { Campaign, Campaigns } from "@/core/interfaces/AgencyAnalytics"
import store from "@/store"
import ApiService from "@/core/services/ApiService"

@Module
export default class AgencyAnalyticsModule
  extends VuexModule
  implements Campaigns<Campaign>
{
  // TODO: Add campaigns to this module via api call
  campaigns = [
    {
      name: "High Level Marketing Michigan",
      id: "1",
      iframe: "https://agencyanalytics.com/"
    }
  ]

  get AgencyAnalyticsCampaigns(): object {
    return this.campaigns
  }

  @Mutation
  [Mutations.SET_ACTIVE_CAMPAIGNS](routeAccountId?: number) {
    // get the campaigns and links
    ;(async () => {
      const accountNum = routeAccountId
        ? routeAccountId
        : store.getters.activeAccountInfo.id.toString()

      ApiService.setHeader()
      let resp

      try {
        resp = await ApiService.get(
          `/accounts/${accountNum}/agency-analytics/dashboard`
        )
      } catch (e) {
        console.error("Problem fetching agency analytics information", e)
        return []
      }
      this.campaigns = [
        {
          iframe: resp.data,
          name: store.getters.activeAccountInfo.details.name,
          id: store.getters.activeAccountInfo.id.toString()
        }
      ]
    })()
  }
}
