import { createStore } from "vuex"
import { config } from "vuex-module-decorators"
import VuexPersistence from "vuex-persist"

import AccountModule from "@/store/modules/AccountModule"
import AuthModule from "@/store/modules/AuthModule"
import BodyModule from "@/store/modules/BodyModule"
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule"
import ConfigModule from "@/store/modules/ConfigModule"

import CSMModule from "@/store/modules/CSMModule"
import AgencyAnalyticsModule from "@/store/modules/AgencyAnalyticsModule"
import LocationModule from "@/store/modules/LocationModule"
import HighlightsModule from "@/store/modules/HighlightsModule"
import MetricsModule from "@/store/modules/MetricsModule"
import AccountServicesModule from "@/store/modules/AccountServicesModule"
import FeedbackModule from "@/store/modules/FeedbackModule"

config.rawError = true

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: any) => ({
    // Modules must be specified here
    // or else they will not be added
    // to the vuex localStorage object
    AccountModule: state.AccountModule,
    AuthModule: state.AuthModule,
    AgencyAnalyticsModule: state.AgencyAnalyticsModule
  })
})

const store = createStore({
  modules: {
    AccountModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    CSMModule,
    AgencyAnalyticsModule,
    LocationModule,
    HighlightsModule,
    MetricsModule,
    AccountServicesModule,
    FeedbackModule
  },
  plugins: [vuexLocal.plugin]
})

export default store
