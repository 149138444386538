import { App } from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import { AxiosResponse, AxiosRequestConfig } from "axios"
import { CustomAxiosConfig } from "../config/CustomAxiosConfig"
import auth from "@/core/helpers/auth"

import store from "@/store"
import { Actions } from "@/store/enums/StoreEnums"

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app
    ApiService.vueInstance.use(VueAxios, axios)
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_FOOTPRINT_API_ENDPOINT ||
      "https://footprint-api.hlmtech.com/"

    // Add response interceptor - log out and send home if response is 401 bad auth
    ApiService.vueInstance.axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data or keep going
        return response
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // error.response object {config: {}, data: {}, headers: {}, request: {}, status: number, statusText: string}
        if (error.response.status == 401) {
          store.dispatch(Actions.LOGOUT)
        }
        return Promise.reject(error)
      }
    )
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.getSignInUserSession().getIdToken().jwtToken}`
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
      "application/json, application/vnd.api+json"
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: CustomAxiosConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: CustomAxiosConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`)
      })
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig | CustomAxiosConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig | CustomAxiosConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params)
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: CustomAxiosConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: CustomAxiosConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params)
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: CustomAxiosConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: CustomAxiosConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params)
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`)
    })
  }
}

export default ApiService
