export type State = { classes: object }
import { Mutations } from "@/store/enums/StoreEnums"
import { Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module
export default class MetricsModule extends VuexModule {
  Metrics = {}

  get KeyMetrics(): object {
    return this.Metrics
  }

  @Mutation
  [Mutations.SET_METRICS](response) {
    if (!response.meta) return []
    this.Metrics = response.meta.key_metrics
  }
}
