
import { defineComponent, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { checkTokenExpiration } from "@/core/helpers/tokenExpiration"

export default defineComponent({
  name: "footprint",
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    onMounted(async () => {
      await router.isReady()

      if (route.params.id) {
        await store.dispatch(Actions.UPDATE_ACCOUNT, route.params.id)
        await store.commit(Mutations.SET_ACTIVE_CAMPAIGNS, route.params.id)
      }

      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG)
      checkTokenExpiration()
    })
  }
})
