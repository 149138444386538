import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import axios from "axios"
import auth from "@/core/helpers/auth"
import router from "../../router/index"

export interface User {
  name: string
  surname: string
  email: string
  password: string
  token: string
}

export interface UserAuthInfo {
  cognitoInfo: object
  loggedIn: boolean
  loadingState: boolean
  errorLoadingState: boolean
}

export interface EntryInfo {
  accountId: number
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = []
  user = {} as User
  entryInfo = {} as EntryInfo
  isAuthenticated = !!JwtService.getToken()

  cognitoInfo = {}
  loggedIn = false
  loadingState = true
  errorLoadingState = false

  set setLoggedIn(newValue) {
    this.loggedIn = newValue
  }

  set setLoggedOut(newValue) {
    this.loggedIn = false
    this.cognitoInfo = {}
  }

  set setCognitoInfo(newValue) {
    this.cognitoInfo = newValue
  }

  get isUserLoggedIn(): string {
    return localStorage.getItem("loggedIn") || ""
  }

  set entryInfoAccountId(newValue) {
    this.entryInfo.accountId = newValue
  }

  get entryInfoAccountId(): number {
    return this.entryInfo.accountId
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error
  }

  @Mutation
  [Mutations.SET_AUTH](response) {
    this.loggedIn = true
    localStorage.setItem("loggedIn", "true")
    this.cognitoInfo = response
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    auth.Signout()
  }

  @Mutation
  [Mutations.GET_USER_INFO_STORAGE_KEY]() {
    const keyPrefix = "CognitoIdentityServiceProvider." + auth.getClientId()
    const tokenUserName = auth.signInUserSession.getAccessToken().getUsername()
    const userInfoKey = keyPrefix + "." + tokenUserName + ".userInfo"
    return userInfoKey
  }

  @Mutation
  [Mutations.GET_USER_INFO]() {
    const jwtToken = auth.getSignInUserSession().getAccessToken().jwtToken
    const USERINFO_URL =
      "https://" + auth.getAppWebDomain() + "/oauth2/userInfo"
    const requestData = {
      headers: {
        Authorization: "Bearer " + jwtToken
      }
    }
    return axios.get(USERINFO_URL, requestData).then((response) => {
      this.setLoggedIn(true)
      this.setCognitoInfo(response)
      return response.data
    })
  }

  @Mutation
  [Mutations.SET_ENTRY_INFO_ACCOUNT_ID](id) {
    this.entryInfo.accountId = id
  }

  @Action({ rawError: true })
  [Actions.LOGIN]() {
    auth.getSession()
  }

  @Action({ rawError: true })
  [Actions.PARSE](currUrl) {
    // auth.parseCognitoWebResponse(currUrl)
    if (auth.parseCognitoWebResponse(currUrl) == undefined)
      router.push({ name: "404" })
  }

  @Action
  [Actions.LOGOUT]() {
    if (this.isUserLoggedIn) {
      auth.signOut()
      localStorage.removeItem("loggedIn")
      // @TODO clear out store
      router.push({ name: "sign-in" })
    }
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data)
          resolve()
        })
        .catch(({ response }) => {
          console.log(response.data.errors)
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    // user data is cached in auth.signInUserSession()
    // auth.isUserSignedIn() will still return true when localStorage cleared
    // token package automatically handles verification, we don't need this
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader()
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data)
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
