export type State = { classes: object }
import { Module, Mutation, VuexModule } from "vuex-module-decorators"
import { Mutations } from "@/store/enums/StoreEnums"
import { AccountServices } from "@/core/interfaces/AccountServices"

@Module
export default class AccountServicesModule extends VuexModule {
  AccountServices = {} as AccountServices

  get AccountServicesOverview(): object {
    return this.AccountServices
  }

  @Mutation
  [Mutations.SET_ACCOUNT_SERVICES](account) {
    this.AccountServices = account.meta.salesforce
  }
}
