import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"
import { ActiveAccount } from "@/core/interfaces/activeAccount"
import store from "@/store"
import AccountService from "@/core/services/AccountService"
import { Account } from "@/core/interfaces/Account"

export interface AccountStoreInfo {
  activeAccount: ActiveAccount
}

@Module
export default class AccountModule extends VuexModule {
  errors = []
  activeAccount = {} as ActiveAccount
  allAccounts = {} as Account

  /**
   * Get current activeAccount object
   * @returns activeAccount
   */
  get activeAccountInfo(): ActiveAccount {
    return {
      id: this.activeAccount.id,
      details: this.activeAccount.details
    }
  }

  get accounts() {
    return this.allAccounts
  }

  @Action
  [Actions.UPDATE_ACCOUNT](id) {
    async function getAccount(id) {
      return await new AccountService().getAccountInfo(id)
    }

    ;(async () => {
      const updatedAccount = await getAccount(id)
      store.commit(Mutations.UPDATE_ACTIVE_ACCOUNT, updatedAccount)
      store.commit(Mutations.SET_LOCATION_LIST, updatedAccount)
      store.commit(Mutations.SET_CSM, updatedAccount)
      store.commit(Mutations.SET_ACCOUNT_SERVICES, updatedAccount)
      store.commit(Mutations.SET_HIGHLIGHTS, updatedAccount)
      store.commit(Mutations.SET_METRICS, updatedAccount)
    })()
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error
  }

  @Mutation
  [Mutations.SET_ACTIVE_ACCOUNT](account) {
    if (store.getters.currentUser.data.type == "TeamMember") {
      // team members might not need this
      this.allAccounts[0] = account

      this.activeAccount = {
        id: account.data.id,
        details: account.data.attributes
      }
    } else {
      // treat as user
      const user = store.getters.currentUser
      this.allAccounts = user.meta.accounts

      this.activeAccount = {
        id: account.data.id,
        details: account.data.attributes
      }
    }
  }

  @Mutation
  [Mutations.UPDATE_ACTIVE_ACCOUNT](account) {
    if (store.getters.currentUser.data.type == "TeamMember") {
      // update activeAccount to match id in url
      this.activeAccount = {
        id: account.data.id,
        details: account.data.attributes
      }
    } else {
      // update activeAccount to match id in url and part of user account access
      this.activeAccount = {
        // @TODO revisit allAccounts
        // details: this.allAccounts[account.id]
        id: account.data.id,
        details: account.data.attributes
      }
    }
  }
}
