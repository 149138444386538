export type State = { classes: object }
import { Mutations } from "@/store/enums/StoreEnums"
import { Module, Mutation, VuexModule } from "vuex-module-decorators"
import { Feedback } from "@/core/interfaces/Feedback"

@Module
export default class FeedbackModule extends VuexModule {
  FeedbackList: Feedback[] = []

  get Feedbacks(): object {
    return this.FeedbackList
  }

  @Mutation
  [Mutations.STORE_FEEDBACKS](response) {
    this.FeedbackList = response
  }
}
