import { createApp } from "vue"
import Footprint from "./Footprint.vue"

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router"
import store from "./store"
import ElementPlus from "element-plus"
import i18n from "@/core/plugins/i18n"

// Datatables
import PrimeVue from "primevue/config"
import DataTable from "primevue/datatable"
import Column from "primevue/column"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import ColumnGroup from "primevue/columngroup"
import Checkbox from "primevue/checkbox"
import RadioButton from "primevue/radiobutton"
import AutoComplete from "primevue/autocomplete"
import Calendar from "primevue/calendar"

import "primevue/resources/primevue.min.css"
import "primevue/resources/themes/saga-blue/theme.css"
import "primeicons/primeicons.css"

//imports for app initialization
import ApiService from "@/core/services/ApiService"
import { initApexCharts } from "@/core/plugins/apexcharts"
import { initInlineSvg } from "@/core/plugins/inline-svg"
import { initVeeValidate } from "@/core/plugins/vee-validate"
import { ErrorMessage, Field, Form } from "vee-validate"
import { VueMasonryPlugin } from "vue-masonry"

import "@/core/plugins/keenthemes"
import "@/core/plugins/prismjs"
import "bootstrap"

const footprint = createApp(Footprint)

footprint.use(store)
footprint.use(router)
footprint.use(ElementPlus)

ApiService.init(footprint)
initApexCharts(footprint)
initInlineSvg(footprint)
initVeeValidate()
footprint.use(VueMasonryPlugin)

footprint.use(i18n)

footprint.use(PrimeVue)
footprint.component("DataTable", DataTable)
footprint.component("Column", Column)
footprint.component("Button", Button)
footprint.component("InputText", InputText)
footprint.component("ColumnGroup", ColumnGroup)
footprint.component("Checkbox", Checkbox)
footprint.component("RadioButton", RadioButton)
footprint.component("Calendar", Calendar)
footprint.component("Form", Form)
footprint.component("Field", Field)
footprint.component("AutoComplete", AutoComplete)
footprint.component("ErrorMessage", ErrorMessage)

footprint.mount("#Footprint")
