export type State = { classes: object }
import { Module, Mutation, VuexModule } from "vuex-module-decorators"
import { Mutations } from "@/store/enums/StoreEnums"
import { Highlights } from "@/core/interfaces/Highlights"

interface StoreInfo {
  Highlights: Highlights
}

@Module
export default class HighlightsModule extends VuexModule implements StoreInfo {
  Highlights = {} as Highlights

  get HighlightsOverview(): object {
    return this.Highlights
  }

  @Mutation
  [Mutations.SET_HIGHLIGHTS](account) {
    this.Highlights = account.meta.highlights
  }
}
