export type State = { classes: object }
import { Mutations } from "@/store/enums/StoreEnums"
import { Module, Mutation, VuexModule } from "vuex-module-decorators"
import { LocationInfo } from "@/core/interfaces/LocationInfo"

@Module
export default class LocationModule extends VuexModule {
  LocationListAll: LocationInfo[] = []

  get LocationsAll(): object {
    return this.LocationListAll
  }

  get LocationsActive(): object {
    return this.LocationListAll.filter((location) => {
      return location.status.toLocaleLowerCase() == "active"
    })
  }

  get LocationsSelected(): object {
    return this.LocationListAll.filter((location) => {
      return location.selected
    })
  }

  @Mutation
  [Mutations.UPDATE_LOCATION_DISPLAY_SINGLE](id) {
    this.LocationListAll.forEach((location) => {
      if (location.id == id) {
        location.selected = !location.selected
      }
    })
  }

  @Mutation
  [Mutations.SET_LOCATION_LIST](response) {
    if (!response.data.relationships.locations) return []
    this.LocationListAll = response.data.relationships.locations.filter(
      (location) => {
        return (
          location.status.toLowerCase() == "active" ||
          location.status.toLowerCase() == "inactive"
        )
      }
    )

    this.LocationListAll.forEach((location) => {
      location.selected = true
    })
  }

  @Mutation
  [Mutations.UPDATE_LOCATION_DISPLAY_ALL](selected: boolean) {
    this.LocationListAll.forEach((location) => {
      location.selected = selected
    })
  }
}
