export type State = { classes: object }
import { Module, Mutation, VuexModule } from "vuex-module-decorators"
import { Mutations } from "@/store/enums/StoreEnums"

interface CSM {
  avatar_url: string
  bio: string
  city?: string
  create_time: number
  email: string
  first_name: string
  hlm_login_id: number
  id: string
  jira_name: string
  job_title: string
  last_name: string
  max_time_for_tasks: number
  meeting_link: string
  phone: string
  salesforce_id: string
  state?: string
  status: string
  team_id: number
  type: string
}

@Module
export default class CSMModule extends VuexModule {
  CSM = {} as CSM

  get CSMOverview(): object {
    return this.CSM
  }

  @Mutation
  [Mutations.SET_CSM](account) {
    this.CSM = account.data.relationships.accountManager
  }
}
