import LayoutConfigTypes from "@/core/config/LayoutConfigTypes"

const config: LayoutConfigTypes = {
  themeName: "Metronic",
  themeVersion: "8.0.21",
  demo: "demo1",
  main: {
    type: "default",
    primaryColor: "#01181C",
    logo: {
      dark: "/media/logos/logomark-footprint.svg",
      light: "/media/logos/logomark-footprint.svg"
    }
  },
  loader: {
    logo: "/media/logos/logomark.svg",
    display: true,
    type: "default"
  },
  scrollTop: {
    display: true
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  toolbar: {
    display: true,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  aside: {
    display: true,
    theme: "dark",
    fixed: true,
    menuIcon: "svg",
    minimized: false,
    minimize: true,
    hoverable: true
  },
  content: {
    width: "fluid"
  },
  footer: {
    width: "fluid"
  }
}

export default config
